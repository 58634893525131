import React, { useState, useEffect, useCallback } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import axios from 'axios';
import JobList from './JobList';
import JobForm from './JobForm';
import { Job } from '../utils/types';

interface JobBoxProps {
  jobBoxHeight: string;
}

const JobBox: React.FC<JobBoxProps> = ({ jobBoxHeight }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [jobs, setJobs] = useState<Job[]>([]);

  const fetchJobs = useCallback(async () => {
    try {
      const response = await axios.get('/api/jobs');
      setJobs(response.data);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  }, []);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const handleNewJob = () => {
    setSelectedJob(null);
    setIsEditing(true);
  };

  const handleJobClick = (job: Job) => {
    setSelectedJob(job);
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    fetchJobs();
  };

  const handleCancel = () => {
    setIsEditing(false);
    setSelectedJob(null);
  };

  const handleDelete = useCallback(async () => {
    if (selectedJob) {
      try {
        await axios.delete(`/api/jobs/${selectedJob.company}`);
        setSelectedJob(null);
        setIsEditing(false);
        fetchJobs();
      } catch (error) {
        console.error('Error deleting job:', error);
      }
    }
  }, [selectedJob, fetchJobs]);

  const noop = () => {};

  return (
    <Box height={jobBoxHeight} alignItems="center" justifyContent="center">
      {!isEditing && (
        <VStack height="100%">
          <JobList
            jobs={jobs}
            onJobClick={handleJobClick}
            onNewJob={handleNewJob}
            jobBoxHeight={jobBoxHeight} // Pass height down to JobList
          />
        </VStack>
      )}
      {isEditing && (
        <JobForm
          job={selectedJob}
          onSave={handleSave}
          onCancel={handleCancel}
          onDelete={selectedJob ? handleDelete : noop}
        />
      )}
    </Box>
  );
};

export default JobBox;
