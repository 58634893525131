// src/components/CommunicationForm.tsx
import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Select, VStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, IconButton } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { Communication } from '../utils/types';

const CommunicationForm: React.FC<{ 
  onSave: (communication: Communication) => void; 
  onCancel: () => void;
  onDelete: () => void;
  communication?: Communication | null;
}> = ({ onSave, onCancel, onDelete, communication }) => {
  const [type, setType] = useState<Communication['type']>(communication?.type || 'Email');
  const [mainContact, setMainContact] = useState<Communication['mainContact']>(communication?.mainContact || '');
  const [summary, setSummary] = useState<Communication['summary']>(communication?.summary || '');
  const [date, setDate] = useState<Communication['date']>(communication?.date || '');
  const [time, setTime] = useState<Communication['time']>(communication?.time || '12:30'); // Set default time to 12:30

  useEffect(() => {
    if (communication) {
      setType(communication.type);
      setMainContact(communication.mainContact);
      setSummary(communication.summary);
      setDate(communication.date);
      setTime(communication.time || '12:30'); // Set time to 12:30 if not provided
    }
  }, [communication]);

  const handleSave = () => {
    const newCommunication: Communication = {
      type,
      mainContact,
      summary,
      date,
      time
    };

    if (date && time) { // Ensure both date and time are provided
      onSave(newCommunication);
    } else {
      alert("Please provide both a date and a time for the communication.");
    }
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <Modal isOpen={true} onClose={onCancel} size="full">
      <ModalOverlay />
      <ModalContent m="2%" bg="white">
        <ModalHeader color="black">Communication Details</ModalHeader>
        <ModalCloseButton color="black" />
        <ModalBody color="black">
          <VStack spacing={4}>
            <FormControl>
              <FormLabel color="black">Type</FormLabel>
              <Select value={type} onChange={(e) => setType(e.target.value)} color="black">
                <option value="Email">Email</option>
                <option value="Phone Call">Phone Call</option>
                <option value="In Person">In Person</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel color="black">Main Contact</FormLabel>
              <Input value={mainContact} onChange={(e) => setMainContact(e.target.value)} color="black" />
            </FormControl>
            <FormControl>
              <FormLabel color="black">Summary of Contact</FormLabel>
              <Input value={summary} onChange={(e) => setSummary(e.target.value)} color="black" />
            </FormControl>
            <FormControl>
              <FormLabel color="black">Date</FormLabel>
              <Input type="date" value={date} onChange={(e) => setDate(e.target.value)} color="black" />
            </FormControl>
            <FormControl>
              <FormLabel color="black">Time</FormLabel>
              <Input
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                color="black"
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={handleDelete} leftIcon={<DeleteIcon />}>
            Delete Communication
          </Button>
          <Button colorScheme="blue" onClick={handleSave}>
            Save Communication
          </Button>
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CommunicationForm;
