import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Checkbox, VStack, Text, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import CommunicationForm from './CommunicationForm';
import { Job, Communication } from '../utils/types';
import axios from 'axios';
import dayjs from 'dayjs';

const JobForm: React.FC<{ job: Job | null; onSave: () => void; onCancel: () => void; onDelete: () => void }> = ({ job, onSave, onCancel, onDelete }) => {
  const [company, setCompany] = useState(job?.company || '');
  const [jobTitle, setJobTitle] = useState(job?.jobTitle || '');
  const [compensation, setCompensation] = useState<number>(job?.compensation || 0); // Ensure compensation is treated as a number
  const [applicationEntered, setApplicationEntered] = useState(job?.applicationEntered || false);
  const [closed, setClosed] = useState(job?.closed || false);
  const [communications, setCommunications] = useState<Communication[]>(job?.communications || []);
  const [isAddingCommunication, setIsAddingCommunication] = useState(false);
  const [selectedCommunication, setSelectedCommunication] = useState<Communication | null>(null);
  const [isDeleting, setIsDeleting] = useState(false); // Prevent multiple delete requests

  const now = dayjs();

  const futureCommunications = communications
    .filter(comm => dayjs(`${comm.date} ${comm.time}`).isAfter(now))
    .sort((a, b) => dayjs(`${a.date} ${a.time}`).diff(dayjs(`${b.date} ${b.time}`)));

  const pastCommunications = communications
    .filter(comm => dayjs(`${comm.date} ${comm.time}`).isBefore(now))
    .sort((a, b) => dayjs(`${b.date} ${b.time}`).diff(dayjs(`${a.date} ${a.time}`)));

  const formatTime = (time: string) => {
    const [hours, minutes] = time.split(':');
    const date = dayjs().hour(parseInt(hours)).minute(parseInt(minutes));
    return date.format('h:mm A'); // Convert to AM/PM format
  };

  const handleSave = async () => {
    const newJob: Job = {
      company,
      jobTitle,
      compensation, // This will now be correctly handled as a number
      applicationEntered,
      closed,
      communications
    };

    try {
      if (job) {
        // If editing an existing job
        await axios.put(`/api/jobs/${company}`, newJob);
      } else {
        // If creating a new job
        await axios.post('/api/jobs', newJob);
      }
      onSave();
    } catch (error) {
      console.error('Error saving job:', error);
    }
  };

  const handleDeleteJob = () => {
    onDelete(); // Trigger the delete action in the parent component
  };

  const handleAddCommunication = (communication: Communication) => {
    if (selectedCommunication) {
      // Update existing communication
      setCommunications(communications.map(comm =>
        comm === selectedCommunication ? communication : comm
      ));
    } else {
      // Add new communication
      setCommunications([...communications, communication]);
    }
    setIsAddingCommunication(false);
    setSelectedCommunication(null); // Clear selected communication after editing
  };

  const handleEditCommunication = (communication: Communication) => {
    setSelectedCommunication(communication);
    setIsAddingCommunication(true);
  };

  const handleDeleteCommunication = (comm: Communication) => {
    setCommunications(communications.filter(c => c !== comm)); // Remove the specific communication
    setSelectedCommunication(null);
    setIsAddingCommunication(false); // Close the communication form after deletion
  };

  return (
    <Modal isOpen={true} onClose={onCancel} size="full">
      <ModalOverlay />
      <ModalContent m="2%" bg="white"> {/* Ensure background color is set */}
        <ModalHeader color="black">Job Details</ModalHeader>
        <ModalCloseButton color="black" /> {/* Set close button color */}
        <ModalBody color="black"> {/* Ensure text is black */}
          <VStack spacing={4}>
            <FormControl>
              <FormLabel color="black">Company</FormLabel> {/* Set label color */}
              <Input value={company} onChange={(e) => setCompany(e.target.value)} color="black" /> {/* Set input text color */}
            </FormControl>
            <FormControl>
              <FormLabel color="black">Job Title</FormLabel> {/* Set label color */}
              <Input value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} color="black" /> {/* Set input text color */}
            </FormControl>
            <FormControl>
              <FormLabel color="black">Compensation</FormLabel> {/* Set label color */}
              <Input
                type="number" // Set input type to number
                value={compensation}
                onChange={(e) => setCompensation(parseInt(e.target.value))} // Parse input as number
                color="black"
              />
            </FormControl>
            <Checkbox isChecked={applicationEntered} onChange={(e) => setApplicationEntered(e.target.checked)} colorScheme="blackAlpha" color="black"> {/* Ensure checkbox text is visible */}
              Application Entered
            </Checkbox>
            <Checkbox isChecked={closed} onChange={(e) => setClosed(e.target.checked)} colorScheme="blackAlpha" color="black"> {/* Ensure checkbox text is visible */}
              Closed?
            </Checkbox>

            {/* Communications List */}
            <Box w="100%">
              <Text fontWeight="bold" color="black">Future Communications</Text>
              <Button onClick={() => { setIsAddingCommunication(true); setSelectedCommunication(null); }} mb={2} colorScheme="blue">
                New Communication
              </Button>
              {futureCommunications.length === 0 && <Text color="black">No future communications</Text>}
              {futureCommunications.map((comm, index) => (
                <Box key={index} borderBottom="1px solid #e2e8f0" p={2} display="flex" alignItems="center">
                  <Box flex="1" onClick={() => handleEditCommunication(comm)}>
                    <Text color="black">{comm.type} with {comm.mainContact} on {comm.date} at {formatTime(comm.time)}</Text>
                    <Text color="black">{comm.summary}</Text>
                  </Box>
                  <IconButton
                    aria-label="Delete Communication"
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteCommunication(comm)} // Call delete and close communication form
                    colorScheme="red"
                    variant="ghost"
                  />
                </Box>
              ))}

              <Text fontWeight="bold" mt={4} color="black">Past Communications</Text>
              {pastCommunications.length === 0 && <Text color="black">No past communications</Text>}
              {pastCommunications.map((comm, index) => (
                <Box key={index} borderBottom="1px solid #e2e8f0" p={2} display="flex" alignItems="center">
                  <Box flex="1" onClick={() => handleEditCommunication(comm)}>
                    <Text color="black">{comm.type} with {comm.mainContact} on {comm.date} at {formatTime(comm.time)}</Text>
                    <Text color="black">{comm.summary}</Text>
                  </Box>
                  <IconButton
                    aria-label="Delete Communication"
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteCommunication(comm)} // Call delete and close communication form
                    colorScheme="red"
                    variant="ghost"
                  />
                </Box>
              ))}

              {isAddingCommunication && (
                <CommunicationForm
                  onSave={handleAddCommunication}
                  onCancel={() => { setIsAddingCommunication(false); setSelectedCommunication(null); }}
                  onDelete={() => handleDeleteCommunication(selectedCommunication!)} // Pass the delete handler
                  communication={selectedCommunication} // Pass selected communication for editing
                />
              )}
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={handleDeleteJob} leftIcon={<DeleteIcon />} disabled={isDeleting}>
            Delete Job
          </Button>
          <Button colorScheme="blue" onClick={handleSave}>
            Save Job
          </Button>
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default JobForm;
