// src/CareerBox.tsx
import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

const CareerBox: React.FC = () => {
  return (
    <Box>
      <Heading as="h3" size="lg" mb={4}>Career</Heading>
      {/* Add more content here */}
    </Box>
  );
};

export default CareerBox;
