import React, { useState, useEffect, useRef } from 'react';
import { ChakraProvider, Box, Flex } from '@chakra-ui/react';
import theme from './themes/theme';
import CareerBox from './components/CareerBox';
import JobBox from './components/JobBox';
import NavBar from './components/NavBar';

const App: React.FC = () => {
  const [selectedBox, setSelectedBox] = useState<'career' | 'job'>('career');
  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.clientHeight);
    }
  }, [navbarRef]);

  const jobBoxHeight = `calc(100vh - ${navbarHeight}px)`;

  return (
    <ChakraProvider theme={theme}>
      <Flex direction="column" height="100vh" overflow="hidden">
        <Box ref={navbarRef}>
          <NavBar onSelect={setSelectedBox} />
        </Box>
        <Box flex="1" overflowY="auto">
          {selectedBox === 'career' && (
            <Box flex="1" mr={2} height={`calc(100vh - ${navbarHeight}px)`}>
              <CareerBox />
            </Box>
          )}
          {selectedBox === 'job' && (
            <Box flex="1" height={`calc(100vh - ${navbarHeight}px)`}>
              <JobBox jobBoxHeight={jobBoxHeight} />
            </Box>
          )}
        </Box>
      </Flex>
    </ChakraProvider>
  );
};

export default App;
