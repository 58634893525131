import React, { useRef } from 'react';
import { Box, Text, Flex, Icon, Button, useTheme } from '@chakra-ui/react';
import { Job } from '../utils/types';
import { FaTimes, FaCheck, FaFileAlt } from 'react-icons/fa';
import dayjs from 'dayjs';

interface JobListProps {
  jobs: Job[];
  onJobClick: (job: Job) => void;
  onNewJob: () => void;
  jobBoxHeight: string; // Accept jobBoxHeight as a prop
}

const JobList: React.FC<JobListProps> = ({ jobs, onJobClick, onNewJob, jobBoxHeight }) => {
  const theme = useTheme();
  const bad = theme.colors.stoplight.red;
  const okay = theme.colors.stoplight.yellow;
  const good = theme.colors.stoplight.green;

  const scrollRef = useRef<HTMLDivElement>(null);

  const handleWheel = (event: React.WheelEvent) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop += event.deltaY;
    }
  };

  const getDaysDifference = (dateTime: dayjs.Dayjs) => {
    return Math.abs(Math.floor(dayjs(dateTime).diff(dayjs(), 'day', true)));
  };

  const getColorForCommunication = (days: number | null) => {
    if (days === null) return 'gray';
    if (days >= 7) return bad;
    if (days >= 3) return okay;
    return good;
  };

  const getColorForCompensation = (compensation: number) => {
    if (compensation >= 130) return good;
    if (compensation >= 100) return okay;
    return bad;
  };

  const Triangle = ({
    days,
    direction,
  }: {
    days: number | null;
    direction: 'left' | 'right';
  }) => {
    const triangleColor = days !== null ? getColorForCommunication(days) : bad;
    const rotation = direction === 'left' ? 'rotate(270deg)' : 'rotate(90deg)';
    const transform = direction === 'left' ? 'translate(-50%, -50%) rotate(90deg)' : 'translate(-50%, -50%) rotate(-90deg)';

    return (
      <Box
        position="relative"
        display="inline-block"
        width="48px"
        height="48px"
        transform={rotation}
      >
        <Box
          width="0"
          height="0"
          borderLeft="24px solid transparent"
          borderRight="24px solid transparent"
          borderBottom={`48px solid ${triangleColor}`}
          position="absolute"
          top="0"
          left="0"
        />
        {days !== null && (
          <Text
            position="absolute"
            top="65%"
            left="50%"
            transform={transform}
            fontSize="20px"
            color="white"
            fontWeight="bold"
          >
            {days}
          </Text>
        )}
      </Box>
    );
  };

  return (
    <Flex direction="column" height={jobBoxHeight} overflow="hidden" width="100%">
      <Box flex="1" overflowY="auto" ref={scrollRef} onWheel={handleWheel}>
        {jobs.length === 0 ? (
          <Text textAlign="center">No jobs available</Text>
        ) : (
          jobs.map((job, index) => {
            const futureCommunications = job.communications
              .filter(comm => dayjs(`${comm.date} ${comm.time}`).isAfter(dayjs()))
              .sort((a, b) => dayjs(`${a.date} ${a.time}`).diff(dayjs(`${b.date} ${b.time}`)));

            const pastCommunications = job.communications
              .filter(comm => dayjs(`${comm.date} ${comm.time}`).isBefore(dayjs()))
              .sort((a, b) => dayjs(`${b.date} ${b.time}`).diff(dayjs(`${a.date} ${a.time}`)));

            const nextCommunication = futureCommunications.length > 0 ? futureCommunications[0] : null;
            const lastCommunication = pastCommunications.length > 0 ? pastCommunications[0] : null;

            const lastCommunicationDays = lastCommunication
              ? getDaysDifference(dayjs(`${lastCommunication.date} ${lastCommunication.time}`))
              : null;
            const nextCommunicationDays = nextCommunication
              ? getDaysDifference(dayjs(`${nextCommunication.date} ${nextCommunication.time}`))
              : null;

            const compensationDisplay = Math.round(job.compensation / 1000);

            const isEven = index % 2 === 0;
            const backgroundColor = index === 0 ? 'black' : isEven ? 'black' : 'white';
            const textColor = index === 0 ? 'white' : isEven ? 'white' : 'black';

            return (
              <Box
                key={index}
                p={4} // Added padding here
                bg={backgroundColor}
                color={textColor}
                onClick={() => onJobClick(job)}
              >
                <Flex justify="space-between" alignItems="center">
                  <Text
                    fontWeight="bold"
                    textAlign="left" // Align company name to the left
                    whiteSpace="normal"
                    overflowWrap="break-word"
                    fontSize="clamp(2rem, 3vh, 4rem)"
                  >
                    {job.company}
                  </Text>
                  <Flex
                    justify="space-evenly"
                    alignItems="center"
                    width="50%" // Take up 50% of the viewport width
                  >
                    <Icon as={job.closed ? FaTimes : FaCheck} color={job.closed ? bad : good} mr={3} boxSize={12} />
                    <Icon as={FaFileAlt} color={job.applicationEntered ? good : bad} mr={3} boxSize={12} />
                    <Box
                      width="7ch" // Ensure space for 3 characters
                      textAlign="center"
                    >
                      <Text
                        color={getColorForCompensation(compensationDisplay)}
                        fontWeight="bold"
                        fontSize="clamp(1rem, 1.5rem, 2rem)"
                      >
                        {compensationDisplay}K
                      </Text>
                    </Box>
                    <Triangle days={lastCommunicationDays} direction="left" />
                    <Triangle days={nextCommunicationDays} direction="right" />
                  </Flex>
                </Flex>
              </Box>
            );
          })
        )}
      </Box>
      <Box
        bg="white"
        p={4}
        textAlign="center"
        width="100%"
      >
        <Button
          onClick={onNewJob}
          size="lg"
          colorScheme="blackAlpha"
        >
          New
        </Button>
      </Box>
    </Flex>
  );
};

export default JobList;
