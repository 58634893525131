import React, { useState } from 'react';
import { Flex, Box, Image, Heading, Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import logo from '../assets/images/logo.png'; // Import the logo image

interface NavBarProps {
  onSelect: (selection: 'career' | 'job') => void;
}

const NavBar: React.FC<NavBarProps> = ({ onSelect }) => {
  const [selectedType, setSelectedType] = useState<'career' | 'job' | 'Select Job Type'>('Select Job Type');

  const handleMenuSelect = (selection: 'career' | 'job') => {
    setSelectedType(selection === 'career' ? 'career' : 'job');
    onSelect(selection);
  };

  return (
    <Flex
      bg="white"
      color="black"
      align="center"
      justify="space-between"
      padding={4}
    >
      <Box>
        <Image src={logo} alt="Logo"/>
      </Box>
      <Heading as="h1" fontSize="clamp(3rem, 4vh, 5rem)" textAlign="center" flex="1">
        Job Hunt
      </Heading>
      <Box>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} colorScheme="blackAlpha" size="lg">
            {selectedType === 'Select Job Type' ? 'Select Job Type' : selectedType.charAt(0).toUpperCase() + selectedType.slice(1)}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => handleMenuSelect('career')}>Career</MenuItem>
            <MenuItem onClick={() => handleMenuSelect('job')}>Job</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};

export default NavBar;
